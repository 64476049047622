import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { Form } from "../../layout/FormLayout";
import type { MethodsOfUseForm, UUID } from "../../types/types";
import { PrimaryButtonFitContainer } from "../Buttons/Buttons";
import { Table } from "../Table/Table";
import { H3, H6 } from "../Typography/Typography";

type TransactionSplitFormProps<T extends object> = {
  formTitle: string;
  subtitle: string;
  buttonText: string;
  columns: Array<T>;
  tableData: Array<any>;
  onSubmit: (values: Record<UUID, boolean>) => Promise<void>;
  methodsOfUseForm: MethodsOfUseForm;
  isLoading: boolean;
};

export function TransactionSplitForm<T extends object>({
  formTitle,
  subtitle,
  buttonText,
  columns,
  tableData,
  methodsOfUseForm,
  onSubmit,
  isLoading,
}: TransactionSplitFormProps<T>) {
  const { watch } = methodsOfUseForm;
  const { t } = useTranslation();
  const values = Object.values(watch());
  const noneChecked = values.every((value: boolean) => value === false);
  const allChecked = values.every((value: boolean) => value === true);
  const isDisabled = allChecked || noneChecked;

  const toolTipText = (() => {
    if (isDisabled) {
      if (allChecked) {
        return t("Cannot select all items");
      } else {
        return t("Select at least one item");
      }
    }
    return null;
  })();

  return (
    <Form onSubmit={methodsOfUseForm.handleSubmit(onSubmit)}>
      <H3>{formTitle}</H3>
      <H6>{subtitle}</H6>
      <Table
        columns={columns}
        isLoading={false}
        data={tableData}
        error={undefined}
      />
      <PrimaryButtonFitContainer
        datatip={toolTipText ?? ""}
        datafor={"submit-button"}
        type="submit"
        loading={isLoading}
        disabled={isDisabled}
      >
        {buttonText}
      </PrimaryButtonFitContainer>
      <ReactTooltip id="submit-button" effect="solid" place="bottom" />
    </Form>
  );
}
