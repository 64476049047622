import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import styled, { useTheme } from "styled-components/macro";
import { z } from "zod";
import { useAuthContext } from "../../../../../../components/Auth";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  EditButton,
  InvisibleButton,
  PrimaryButtonWithPlusIcon,
} from "../../../../../../components/Buttons/Buttons";
import { IconGroup } from "../../../../../../components/DocumentCard/DocumentCard";
import { NonVisibleIcon } from "../../../../../../components/Icons/Icons";
import { Table } from "../../../../../../components/Table/Table";
import { ToggleSwitch } from "../../../../../../components/ToggleSwitch/ToggleSwitch";
import { H3 } from "../../../../../../components/Typography/Typography";
import type { OptionType, UUID } from "../../../../../../types/types";
import type {
  AttributeSchema,
  ProductReference,
} from "../../../../../../types/types.PIM";
import {
  rowHover,
  TablePlaceholder,
  useStoreState,
} from "../../../../../../util/util";
import type {
  FormValue,
  IRow,
  OnSubmitValue,
  ProductCollectionSchemaTableProps,
} from "./type";
import {
  ActiveCheckIcon,
  createFormFieldForCollection,
  DestructiveXIcon,
  GENERATED_UUID,
  GenerateForm,
  generateUUID,
  getAttributeValue,
  getValidationForProductCollection,
} from "./ProductCollectionSchema.util";
import { useNotifications } from "../../../../../../components/Notifications/NotificationsContext";
import type { AxiosError } from "axios";
import { zodResolver } from "@hookform/resolvers/zod";
import type { DeepMap, FieldError, UseFormMethods } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useRoutePath } from "../../../../../../util/Routing";
import { LinkAttributeValueSchema } from "../../../../../../util/zod.util";
import { Link } from "react-router-dom";

const TableContainer = styled.div`
  margin-bottom: 24px;
  overflow-x: scroll;
  & div[class*="TableWrapper"] {
    /* overflow: visible; */

    table tbody td {
      input {
        padding: 7px 4px;
        font-size: ${({ theme }) => theme.fontSizes.small} !important;
        height: auto !important;
        min-width: 110px;
        &::placeholder {
          font-size: ${({ theme }) => theme.fontSizes.xs} !important;
        }
      }
    }
  }

  .__react_component_tooltip {
    ul {
      margin: 0;
      list-style: inside;
      padding: 0;
    }
    li {
      margin: 0;
      padding: 2px 0;
    }
  }
`;

const RestrictedAccessHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const NonVisibileContainer = styled.div`
  width: fit-content;
  height: fit-content;
`;

const NoEditPlaceholder = (t: any) => (
  <TablePlaceholder message={t("No items to show.")} />
);

export const ProductCollectionSchemaTable = ({
  collectionSchema,
  onSubmitForm,
  onDeleteRow,
  title,
  isPortfolio = false,
  productStatus,
  isEditable,
}: ProductCollectionSchemaTableProps) => {
  const [tableData, setTableData] = useState<IRow[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rowInEdit, setRowInEdit] = useState<UUID>();
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  const { storePath, adminPath } = useRoutePath();
  const theme = useTheme();

  const methodFormRef = useRef<UseFormMethods<FormValue> | null>(null);
  const errorRef = useRef<DeepMap<FormValue, FieldError> | null>(null);

  const { notifySuccess, notifyError } = useNotifications();
  const { hasPermission, roleIsSellerAdmin, user } = useAuthContext();

  const getValidationSchema = () => {
    let validationSchemaObj: {
      [prop: string]:
        | z.ZodString
        | z.ZodBoolean
        | z.ZodSchema
        | z.ZodOptional<z.ZodString | z.ZodBoolean | z.ZodSchema>;
    } = {};

    const columns = !isPortfolio
      ? collectionSchema?.columns
      : collectionSchema?.columns.filter(({ is_restricted }) => !is_restricted);

    columns.forEach((col) => {
      validationSchemaObj = {
        ...validationSchemaObj,
        [col.id]: getValidationForProductCollection({
          input_type: col.input_type,
          optional: !col.is_required,
          t,
        }),
      };
    });

    return z.object(validationSchemaObj).superRefine((formValue, ctx) => {
      if (
        Object.values(formValue).every(
          (
            value: string | boolean | string[] | OptionType<any> | undefined
          ) => {
            if (typeof value === "string") {
              return value === "";
            } else if (Array.isArray(value)) {
              return value.length === 0;
            } else if (typeof value === "object") {
              return !value;
            } else {
              return value === undefined || value === null;
            }
          }
        )
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("Select at least one field"),
          path: ["select_a_field"],
        });
      }
    });
  };

  const newMethodForm = useForm({
    resolver: zodResolver(getValidationSchema()),
  });

  if (methodFormRef.current === null) {
    methodFormRef.current = newMethodForm;
  }

  const getFormsData = (value: FormValue) =>
    Object.entries(value).reduce<FormValue>((acc, [key, value]) => {
      if (value && typeof value === "object" && !Array.isArray(value)) {
        acc[key] = [{ name: `${value.value}` }];
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});

  const onSubmit = async (value: OnSubmitValue) => {
    setIsSubmitting(true);
    setTableData((prev) =>
      prev.map((row) => ({
        ...row,
        edit: value.row_id === row.uuid ? false : row.edit,
      }))
    );
    try {
      await onSubmitForm(value);
      notifySuccess(t("Your changes have been saved successfully"));
      setRowInEdit(undefined);
    } catch (error) {
      setIsSubmitting(false);
      const { row_id, ...formValue } = value;
      setTableData((prev) =>
        prev.map((row) =>
          row_id === row.uuid
            ? { ...row, ...getFormsData(formValue), edit: true }
            : row
        )
      );
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error modifying this collection"),
        {
          error,
        }
      );
    }
  };

  const addItem = () => {
    const uuid = generateUUID(tableData);
    setTableData((prev) => [
      ...prev,
      {
        edit: true,
        row_editable: true,
        uuid,
      },
    ]);
    setRowInEdit(uuid);
  };

  const displayValue = useCallback(
    (
      col: AttributeSchema,
      value:
        | string
        | boolean
        | number
        | { name: string }[]
        | ProductReference
        | { display_text: string; url: string }
        | null,
      isPortfolio?: boolean
    ) => {
      switch (col.input_type) {
        case "single_select":
          return (value as { name: string }[])?.[0]?.name ?? "--";
        // product reference attribute
        case "product_reference":
          const reference = (value as ProductReference[])?.[0];
          const tenantHasAccess = user?.tenant_id
            ? reference?.visibility_tenants
                ?.map((tenant) => tenant.id)
                ?.includes(user?.tenant_id)
            : false;
          if (
            (reference?.status === "published" &&
              reference?.is_visible_on_storefronts &&
              (reference?.is_accessible || tenantHasAccess)) ||
            roleIsSellerAdmin ||
            (reference?.status === "published" &&
              reference?.is_accessible &&
              user) ||
            (reference?.status === "published" && tenantHasAccess)
          ) {
            return (
              <>
                {reference ? (
                  <Link
                    to={
                      isPortfolio
                        ? `${storePath}/product/${reference?.slug}`
                        : `${adminPath}/pim/products/${
                            reference?.product_number ?? reference?.id
                          }`
                    }
                  >
                    {reference.name}
                  </Link>
                ) : (
                  "--"
                )}
              </>
            );
          } else {
            return (
              <>
                <span
                  data-tip={
                    reference?.status !== "published"
                      ? t("Product informations is unavailable to view")
                      : t("Product informations is not accessible")
                  }
                  data-for={reference?.id}
                >
                  {reference?.name}
                </span>
                <ReactTooltip
                  delayHide={200}
                  id={reference?.id}
                  effect="solid"
                />
              </>
            );
          }
        case "numeric":
          return value ? value : "--";
        case "date":
          return value ? moment(value as string).format("MMM DD, YYYY") : "--"; // moment is always truthy never falsy, using ternary instead
        case "form_field":
          return value ? value : "--";
        case "multiline_entry":
          return (
            <>
              {value ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<div class="ql-editor">${value as string}</div>`,
                  }}
                />
              ) : (
                "--"
              )}
            </>
          );
        case "toggle":
        case "checkbox":
          return isPortfolio ? (
            <span>{!!value ? "Yes" : "No"}</span>
          ) : (
            <>
              <ToggleSwitch
                label={""}
                name={col.id}
                isChecked={(value as unknown as boolean) ?? false}
                disabled={true}
              />
            </>
          );
        case "multi_select":
          const multiselectValue = value as
            | { name: string }[]
            | null
            | undefined;
          const cellID = Math.floor((1 + Math.random()) * 0x10000);
          return multiselectValue?.length ? (
            <>
              <span
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  display: "inline-block",
                }}
                data-for={`row-tooltip-${cellID}`}
                data-tip={""}
              >
                {multiselectValue.map(({ name }) => name).join(", ")}
              </span>
              {multiselectValue?.length > 1 && (
                <ReactTooltip
                  id={`row-tooltip-${cellID}`}
                  place="top"
                  data-html={true}
                  effect="solid"
                  backgroundColor="#60676f"
                  multiline={true}
                >
                  <div>
                    <ul>
                      {multiselectValue.map(({ name }) => {
                        return <li key={name}>{name}</li>;
                      })}
                    </ul>
                  </div>
                </ReactTooltip>
              )}
            </>
          ) : (
            "--"
          );

        case "link":
          // Even though its called "link", we return just the display_text if
          // the url doesn't exist. Which it might not.
          const parsed = LinkAttributeValueSchema.safeParse(value);

          if (parsed.success && parsed.data.url) {
            return (
              <a
                href={parsed.data.url as string}
                target={"_blank"}
                rel="noreferrer"
              >
                {parsed.data.display_text}
              </a>
            );
          } else if (
            parsed.success &&
            !parsed.data.url &&
            parsed.data.display_text !== ""
          ) {
            return parsed.data.display_text;
          } else return "--";

        default:
          return "--";
      }
    },
    [roleIsSellerAdmin, user, storePath, adminPath, t]
  );

  const tableColumns = useMemo(() => {
    const handleEditRow = (id: UUID) => {
      setTableData((prev) =>
        [...prev].map((row) => (row.uuid === id ? { ...row, edit: true } : row))
      );
      setRowInEdit(id);
    };

    const handleDeleteRow = async (id: UUID) => {
      try {
        await onDeleteRow(id);
        setTableData((prev) =>
          prev.reduce<IRow[]>((acc, cur) => {
            if (cur.uuid !== id) {
              acc.push(cur);
            }
            return acc;
          }, [])
        );
        notifySuccess(t("Item deleted successfully."));
      } catch (error) {
        const errorMessage = (error as AxiosError)?.response?.data?.message;
        notifyError(
          errorMessage
            ? errorMessage
            : t("Could not delete row. Please try again later"),
          {
            error,
          }
        );
      }
    };

    const exitEditMode = (row: IRow) => {
      if (row.uuid.includes(GENERATED_UUID)) {
        setTableData((prev) =>
          prev.reduce<IRow[]>((acc, cur) => {
            if (cur.uuid !== row.uuid) {
              acc.push(cur);
            }
            return acc;
          }, [])
        );
      } else {
        setTableData((prev) => {
          const newData = prev.reduce<IRow[]>((acc, cur) => {
            acc.push(cur.uuid === row.uuid ? { ...cur, edit: false } : cur);
            return acc;
          }, []);
          return newData;
        });
      }
      setIsSubmitting(false);
      setRowInEdit(undefined);
    };

    const columns = !isPortfolio
      ? collectionSchema?.columns
      : collectionSchema?.columns.filter(({ is_restricted }) => !is_restricted);

    return columns.reduce<
      {
        Header: string | JSX.Element;
        accessor: string;
        [prop: string]: any;
      }[]
    >((acc, col, index) => {
      //Hide Multiline_entry from collection table
      if (col.input_type !== "multiline_entry") {
        acc.push({
          Header: !col.is_restricted ? (
            col.display_name ? (
              t([col.display_name])
            ) : (
              col.name
            )
          ) : (
            <>
              <RestrictedAccessHeader>
                {col.display_name ? t([col.display_name]) : col.name}
                <NonVisibileContainer
                  data-tip={t(
                    "This attribute is only visible for internal users."
                  )}
                  data-for="non-visible-tip"
                >
                  <NonVisibleIcon
                    width={15.7}
                    height={15.7}
                    fill={theme.secondaryIconColor}
                  />
                </NonVisibileContainer>
                <ReactTooltip
                  delayHide={500}
                  id="non-visible-tip"
                  effect="solid"
                />
              </RestrictedAccessHeader>
            </>
          ),
          accessor: col.id,
          overflow:
            col.input_type === "single_select" ||
            col.input_type === "date" ||
            col.input_type === "multi_select" ||
            col.input_type === "product_reference"
              ? "visible"
              : "hidden",
          Cell: ({
            row: { original },
            value,
          }: {
            row: { original: IRow };
            value: string | null | boolean | { name: string }[];
          }) =>
            original.edit && !!methodFormRef.current
              ? createFormFieldForCollection({
                  col,
                  value,
                  row: original,
                  methodsOfUseForm: methodFormRef.current,
                  errors: errorRef.current ?? {},
                  storefront_id: storefront_id,
                  t,
                })
              : displayValue(col, value, isPortfolio),
        });
      }
      if (
        index === columns.length - 1 &&
        !isPortfolio &&
        productStatus !== "archived"
      ) {
        acc.push({
          Header: "",
          accessor: " ",
          disableSortBy: true,
          Cell: ({ row: { original } }: { row: { original: IRow } }) =>
            original.edit ? (
              <IconGroup style={{ justifyContent: "flex-end" }}>
                <InvisibleButton
                  disabled={isSubmitting}
                  form={original.uuid}
                  type="submit"
                >
                  <ActiveCheckIcon />
                </InvisibleButton>
                <InvisibleButton
                  type="button"
                  onClick={() => exitEditMode(original)}
                >
                  <DestructiveXIcon />
                </InvisibleButton>
              </IconGroup>
            ) : (
              <IconGroup style={{ justifyContent: "flex-end" }}>
                {hasPermission("delete_products") &&
                  isEditable &&
                  original.row_editable && (
                    <ButtonWithConfirmDialog
                      Button={(props) => <DeleteButton {...props} />}
                      testid={"remove-row-from-collection"}
                      handleConfirm={() => handleDeleteRow(original.uuid)}
                      confirmMessage={t(
                        "Are you sure you want to delete this row?"
                      )}
                    />
                  )}
                {hasPermission("modify_products") &&
                  isEditable &&
                  original.row_editable && (
                    <EditButton
                      disabled={Boolean(rowInEdit)}
                      onClick={() => handleEditRow(original.uuid)}
                    />
                  )}
              </IconGroup>
            ),
        });
      }
      return acc;
    }, []);
  }, [
    displayValue,
    isPortfolio,
    collectionSchema?.columns,
    onDeleteRow,
    notifySuccess,
    t,
    notifyError,
    productStatus,
    theme.secondaryIconColor,
    isSubmitting,
    hasPermission,
    storefront_id,
    rowInEdit,
    isEditable,
  ]);

  useEffect(() => {
    setTableData((prev) => {
      const newRowInEdit: IRow[] = [];
      const existingRowInEdit: { [prop: string]: boolean } = {};
      if (prev.length) {
        newRowInEdit.push(
          ...prev.reduce<IRow[]>((acc, cur) => {
            if (cur.edit && cur.uuid.includes(GENERATED_UUID)) {
              acc.push(cur);
            }
            if (cur.edit && !cur.uuid.includes(GENERATED_UUID)) {
              existingRowInEdit[cur.uuid] = true;
            }
            return acc;
          }, [])
        );
      }
      const dataFromDB = (collectionSchema?.value_rows ?? []).reduce<IRow[]>(
        (acc, row) => {
          const temp = row.values.reduce<IRow>(
            (attributeValueAcc, attrValue) => ({
              ...attributeValueAcc,
              [attrValue.attribute_id]: getAttributeValue(
                attrValue,
                attributeValueAcc
              ),
              uuid: row.row_id,
              edit: row.is_editable && row.row_id in existingRowInEdit,
            }),
            {
              uuid: row.row_id,
              edit: false,
              row_editable: row.is_editable,
              id: `row-${row.row_id}`,
            }
          );
          return row.values.length > 0 ? [...acc, temp] : acc;
        },
        []
      );
      return [...dataFromDB, ...newRowInEdit];
    });
    setIsSubmitting(false);
  }, [collectionSchema?.value_rows]);

  return (
    <>
      <H3>{title}</H3>
      <TableContainer>
        <div>
          <Table
            columns={tableColumns}
            data={tableData}
            lastChildleftAlign
            isLoading={false}
            enableDefaultSort={true}
            error={undefined}
            Placeholder={
              !isPortfolio ? TablePlaceholder({}) : NoEditPlaceholder(t)
            }
            rowHover={rowHover}
          />
          {Boolean(rowInEdit) && Boolean(methodFormRef.current) && (
            <GenerateForm
              rowInEdit={rowInEdit!}
              methodForm={methodFormRef.current}
              onSubmitForm={onSubmit}
              errorRef={errorRef}
            />
          )}
        </div>
      </TableContainer>
      {isPortfolio || productStatus === "archived" ? (
        <></>
      ) : (
        !Boolean(rowInEdit) && (
          <div>
            <PrimaryButtonWithPlusIcon
              style={{ width: "fit-content" }}
              onClick={addItem}
              disabled={!hasPermission("modify_products") || !isEditable}
              datafor={"modify-products"}
              datatip={
                !hasPermission("modify_products")
                  ? t("You don't have permission to modify this product")
                  : !isEditable
                  ? t(
                      "This product is assigned to 1 or more teams and cannot be edited"
                    )
                  : ""
              }
            >
              {t("Add")}
            </PrimaryButtonWithPlusIcon>
            <ReactTooltip id="modify-products" />
          </div>
        )
      )}
    </>
  );
};
